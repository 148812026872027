import { useI18n, useLocaleRoute } from '#i18n'

export const useMenu = definePiniaStore('menus', () => {
  const mainMenu: Ref<any[]> = ref([])
  const projectMenu: Ref<any[]> = ref([])
  const journalMenu: Ref<any[]> = ref([])
  const loading: Ref<boolean> = ref(false)

  const router = useRouter()
  const localeRoute = useLocaleRoute()
  const { locale } = useI18n()
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()

  async function getMainMenu(): Promise<void> {
    loading.value = true
    const route = router.currentRoute.value

    try {
      const data: any = await $fetch('umbraco/delivery/api/v1/content', {
        params: {
          expand: 'all',
          fetch: 'descendants:menu',
          take: 1000,
          skip: 0,
        },
        method: 'GET',
        baseURL: config.public.baseURL,
        headers: {
          'Accept-Language':
            (nuxtApp.$i18n as any).locale.value === 'en' ? 'en-US' : 'ka',
        },
      })
      if (data.items) {
        let menuItems = data.items.filter(
          (item: any) => item.contentType === 'menuItems'
        )
        const menuChildren = data.items.filter(
          (item: any) => item.contentType === 'menuItem'
        )
        for (let i = 0; i < menuItems.length; i++) {
          const menuItem = menuItems[i]
          let children = menuChildren.filter((child: any) => {
            const lastItem = menuItem.route.path
              .split('/')
              .filter((item: string) => item !== '')
              .slice(-1)
            return child.route.path.includes(lastItem)
          })
          children = children.sort(
            (a: any, b: any) => a.properties.order - b.properties.order
          )
          menuItem.children = children
        }
        menuItems = menuItems.sort(
          (a: any, b: any) =>
            a.properties.menuItemsOrdering - b.properties.menuItemsOrdering
        )
        mainMenu.value = menuItems
      }
    } catch (error) {
      console.error('Failed to fetch main menu:', error)
    } finally {
      loading.value = false
    }
  }

  async function getProjectMenu(slug: string): Promise<void> {
    loading.value = true
    const route = router.currentRoute.value

    try {
      const data: any = await $fetch('umbraco/delivery/api/v1/content', {
        params: {
          expand: 'all',
          fetch: `children:${locale.value}/projects/${decodeURI(slug)}/${
            locale.value === 'en' ? 'menu' : 'მენიუ'
          }`,
          take: 1000,
          skip: 0,
        },
        method: 'GET',
        baseURL: config.public.baseURL,
        headers: {
          'Accept-Language':
            (nuxtApp.$i18n as any).locale.value === 'en' ? 'en-US' : 'ka',
        },
        onResponse: async ({ response, request }) => {
          if (response?._data?.items && response?._data?.items.length > 0) {
            response?._data?.items.sort((a: any, b: any) => {
              return (a.properties.order ?? 0) > (b.properties.order ?? 0)
                ? 1
                : -1
            })
          }
          if (!route.params.slug || route.params.slug.length < 3) {
            await nextTick()
            await navigateTo(
              response._data?.items[0].properties?.link[0]?.route?.path
                ? response._data?.items[0].properties?.link[0]?.route?.path
                : response._data?.items[0].properties?.link[0]?.url,
              {
                replace: true,
              }
            )
          }
        },
      })
      if (data.items) {
        projectMenu.value = data.items
      }
    } catch (error) {
      console.error('Failed to fetch project menu:', error)
    } finally {
      loading.value = false
    }
  }

  async function getJournalMenu(): Promise<void> {
    loading.value = true
    const route = router.currentRoute.value

    try {
      const data: any = await $fetch('umbraco/delivery/api/v1/content', {
        params: {
          expand: 'all',
          fetch: `descendants:${locale.value}/journal/georgian-law-review/georgian-law-review-menu/`,
          take: 1000,
          skip: 0,
        },
        method: 'GET',
        baseURL: config.public.baseURL,
        headers: {
          'Accept-Language':
            (nuxtApp.$i18n as any).locale.value === 'en' ? 'en-US' : 'ka',
        },
        onResponse: async ({ response, request }) => {
          if (response?._data?.items && response?._data?.items.length > 0) {
            response?._data?.items.sort((a: any, b: any) => {
              return (a.properties.order ?? 0) > (b.properties.order ?? 0)
                ? 1
                : -1
            })
          }
        },
      })
      if (data.items) {
        journalMenu.value = data.items
      }
    } catch (error) {
      console.error('Failed to fetch project menu:', error)
    } finally {
      loading.value = false
    }
  }

  function clearProjectMenu(): void {
    projectMenu.value = []
    loading.value = false
  }

  function clearJournalMenu(): void {
    journalMenu.value = []
    loading.value = false
  }

  return {
    mainMenu,
    projectMenu,
    journalMenu,
    loading,
    getMainMenu,
    getProjectMenu,
    clearProjectMenu,
    getJournalMenu,
    clearJournalMenu,
  }
})
